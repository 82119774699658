<template>
  <div class="page-404">
    <common-header></common-header>
    <page-not-found></page-not-found>
    <footer-menu></footer-menu>
  </div>
</template>

<script>
import { CommonHeader, FooterMenu, PageNotFound } from "@/components/common";

export default {
  name: "Page404",
  components: {
    CommonHeader,
    FooterMenu,
    PageNotFound
  }
}
</script>
